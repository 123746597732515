import Cookies from "js-cookie"

import { logDecodingError } from "../helpers/airbrake"
import { Elm } from "../components/elm/Navbar.elm"
import { pushGa4Event } from "../helpers/ga4_helper"
import { loadBackgroundImages } from "../helpers/image_loader"
import { isTabletPortrait } from "../helpers/window_size"

declare global {
  interface Window {
    navFlags: Flags
  }
}

interface DrawerStateChangeParams {
  drawerId: string,
  isOpen: boolean
}

const PROMO_COOKIE = "sitewide_promotion"
const MAIN_NAV_DRAWER_ID = "main-navbar-drawer"
const ACCOUNT_DRAWER_ID = "my-account-drawer"
const IMAGE_ITEM_CLASS = "sb-navbar__drawer-item-image"
const container: HTMLElement | null = document.getElementById('elm-navbar')

let user : string | null = null
try {
  user = localStorage.getItem('user')
} catch(_) {
  // Ignore security errors on Safari
}

const onFocus = (drawerId: string): void => {
  const pushMenu: HTMLElement | null = document.getElementById(drawerId)
  if (!pushMenu) return

  const focusableElements = 'button, [href], [tabindex]:not([tabindex="-1"])'
  const activeSlide: Element = pushMenu.querySelectorAll("[aria-modal]:not([hidden])")[0]
  if (!activeSlide) return

  const firstFocusableElement = activeSlide.querySelectorAll(focusableElements)[0]
  const isActive =
    firstFocusableElement instanceof HTMLElement && !activeSlide.contains(document.activeElement)
  if (isActive) firstFocusableElement.focus()

}

const trackPromoClicks = (): void => {
  const promo = document.getElementById("js-sitewide-promo")
  const promoName = promo?.dataset.promoName
  if (!promo || !promoName) return

  // Presence of element is sufficient to trigger a "view"
  pushGa4Event({ event: "view_promotion", promotion_name: promoName })

  promo.addEventListener("click", (e: Event) => {
    const link = (<HTMLElement>e.target).closest("a")
    if (!link) return

    pushGa4Event({ event: "select_promotion", promotion_name: promoName })
  })
}

export default (): void => {
  if (container) {
    const images = document.getElementsByClassName(IMAGE_ITEM_CLASS) // `images` is a live list
    const flags: Flags = {
      ...window.navFlags,
      mainNavDrawerId: MAIN_NAV_DRAWER_ID,
      accountDrawerId: ACCOUNT_DRAWER_ID,
      isMobileNav: isTabletPortrait()
    }

    if (user) { flags['user'] = JSON.parse(user) }
    const app = Elm.Navbar.init({ node: container, flags: flags })

    app.ports.ready.subscribe(() => {
      const drawer = document.getElementById(MAIN_NAV_DRAWER_ID)
      if (!drawer) return

      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            loadBackgroundImages([...images] as HTMLElement[])
          }
        })
      })

      mutationObserver.observe(drawer, { subtree: true, childList: true })
    })

    app.ports.flagDecodingError.subscribe(() => logDecodingError(flags))
    app.ports.trackPromoClicks.subscribe(trackPromoClicks)
    app.ports.drawerStateChange.subscribe((drawerState: DrawerStateChangeParams) => {
      document.body.classList.toggle("--mobile-nav-open", drawerState.isOpen)

      if (drawerState.isOpen) {
        loadBackgroundImages([...images] as HTMLElement[])
        document.addEventListener("focus", () => onFocus(drawerState.drawerId), true)
      } else {
        document.removeEventListener("focus", () => onFocus(drawerState.drawerId), true)
      }

    })
    app.ports.closePromo.subscribe(() => {
      Cookies.set(PROMO_COOKIE, "hide")
    })
  }
}
